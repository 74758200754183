import React, { Component } from 'react';
import './SectionArticleItem.scss';
import PropTypes from 'prop-types';
import config from '../../../config';
import * as bridge from '../../../bridge';

class SectionArticleItem extends Component {
    constructor (props) {
        super(props);

        this.bookCover = React.createRef();
        this.state = {
            imageStatus: false
        };
        this.unmount = false;
    }

    UNSAFE_componentWillMount () {
        const { article } = this.props;
        const img = new Image();
        img.src = article.image;
        img.onload = () => {
            if (this.unmount) {
                return;
            }
            this.setState({
                imageStatus: true
            });
        };
    }

    render () {
        const { article, style } = this.props;
        return (
            <div className="section-article-item template-1" key={article.id} style={style}>
                <a onClick={SectionArticleItem.handleClick.bind(this, article.id, article.name)}>
                    <div className="article-item-left">
                        {this.renderBookCover(article.image, article.name)}
                    </div>
                    <div className="article-item-right">
                        <div className="article-item-name">{this.highlightFont()}</div>
                        <div className="article-item-desc">{article.description}</div>
                        <div className="article-item-author iconfont">{article.author}</div>
                        <div className="article-item-tags">
                            {this.renderBookStatus(article.status)}
                            <div className="tag-type">{article.typeName}</div>
                        </div>
                    </div>
                </a>
            </div>
        );
    }

    renderBookCover (coverUrl, articleName) {
        if (!this.state.imageStatus) {
            coverUrl = config.loadingImage;
        }

        return <img ref={this.bookCover} onError={this.handleErrorImage.bind(this)} src={coverUrl} className="article-item-img" alt={articleName}/>;
    }

    highlightFont () {
        const { article, highlight } = this.props;
        if (highlight === undefined) {
            return article.name;
        }

        const nameChars = article.name.split('');
        const highlightChars = highlight.split('');
        const arr = nameChars.map((nameChar, index) => {
            for (let i = 0, len = highlightChars.length; i < len; i++) {
                if (nameChar === highlightChars[i]) {
                    return (<span key={index} className='keyword-red'>{nameChar}</span>);
                }
            }

            return nameChar;
        });

        return arr;
    }

    handleErrorImage () {
        const imgDom = this.bookCover.current;
        if (imgDom) {
            imgDom.src = config.defaultImage;
        }
    }

    static handleClick (articleId, articleName) {
        const href = window.location.origin + '/details.html#/' + articleId;
        bridge.openWindow(href, articleName, 0, 1, '0xF6F6F6');
    }

    renderBookStatus (status) {
        const cls = 'tag-status';
        if (status) {
            return <div className={cls}>完本</div>;
        }
        return <div className={cls}>连载</div>;
    }

    componentWillUnmount () {
        this.unmount = true;
    }
}

SectionArticleItem.propTypes = {
    article: PropTypes.object.isRequired,
    highlight: PropTypes.string,
    style: PropTypes.object.isRequired
};

export default SectionArticleItem;
