;(function (w) {
    class ScrollLoad {
        constructor (config) {
            const {
                loadOffset = 30, loadFun = () => {
                }, initPage = 1, scrollArea = window,
                autoLoad = true
            } = config;

            this.loadOffset = loadOffset;
            this.callback = loadFun;
            this.page = initPage;
            this.scrollArea = scrollArea;
            this.loadMore = this.loadMore.bind(this);
            this.isReady = true;
            this.autoLoad = autoLoad;

            this.element = null;
            this.target = null;
            this.isWindow = false;

            this.init();

            this.addScrollEvent();

            if (this.autoLoad) {
                this.loadPage(this.page);
            }
        }

        init () {
            const select = this.scrollArea;

            if (!select) {
                return;
            }

            if (typeof select === 'string') {
                const node = document.querySelector(select);
                if (node) {
                    this.element = node;
                }
            }

            if (typeof select === 'object') {
                this.element = select;
            }

            if (select instanceof Window || select.nodeType === 9) {
                this.target = document.documentElement;
                this.isWindow = true;
            } else {
                this.target = this.element;
            }
        }

        loadPage (page, add) {
            this.isReady = false;
            this.page = page;
            this.callback(page, this);
            this.page++;
        }

        removeScrollEvent () {
            if (this.element) {
                this.element.removeEventListener('scroll', this.loadMore);
            }
        }

        addScrollEvent () {
            if (this.element) {
                this.element.addEventListener('scroll', this.loadMore);
            }
        }

        realHeight () {
            return this.target.scrollHeight;
        }

        scrollTop () {
            if (this.isWindow) {
                return window.pageYOffset;
            }
            return this.target.scrollTop;
        }

        eleHeight () {
            return this.target.clientHeight;
        }

        loadMore () {
            const realHeight = this.realHeight();
            const scrollTop = this.scrollTop();
            const eleHeight = this.eleHeight();
            if (eleHeight + scrollTop + this.loadOffset >= realHeight && this.isReady) {
                this.loadPage(this.page);
            }
        }

        ready () {
            this.isReady = true;
        }

        lock () {
            this.isReady = false;
        }
    }

    module.exports = ScrollLoad;
})(window);
