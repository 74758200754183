import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './Loading.scss';

const Loading = (props) => {
    const { loading, pageEnd, prompt } = props;

    let promptStr = '没有更多书籍了';

    if (prompt !== undefined) {
        promptStr = props.prompt;
    }

    if (pageEnd) {
        return <div className="page-end-box">{promptStr}</div>;
    }

    if (loading) {
        return <div className="loading-box iconfont">加载中</div>;
    }
    return <Fragment></Fragment>;
};

Loading.propTypes = {
    pageEnd: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    prompt: PropTypes.string
};

export default Loading;
